












































import Vue from "vue";
import { DataTableHeader } from "vuetify";
import EditPackDialog from "@/components/EditPackDialog.vue";
import AddPackDialog from "@/components/AddPackDialog.vue";

export default Vue.extend({
  components: {
    AddPackDialog,
    EditPackDialog,
  },
  async beforeMount() {
    await this.$store.dispatch("getPacks");
    await this.$store.dispatch("getCategories", "move");
  },
  data: () => ({
    editIndex: 0,
    editDialog: false,
    addDialog: false,
    headers: [
      {
        text: "Pack #",
        value: "number",
        width: 100,
        sortable: false
      },
      {
        text: "Nom",
        value: "name",
        sortable: false
      },
      {
        text: "",
        value: "tools",
        width: 50,
        sortable: false
      },
    ] as DataTableHeader[],
  }),
  methods: {
    postUpdateCycle() {
      this.$store.dispatch("getPacks");
    },
    openEditDialog(item: Record<string, any>) {
      this.editIndex = item.id;
      this.editDialog = true;
    },
    closeEditDialog() {
      this.editIndex = 0;
      this.editDialog = false;
    },
  },
});
