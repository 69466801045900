



























































































































































































































import base64Images from "@/modules/base64-images";
import Request from "@/modules/request";
import { MediaType } from "@/store/interfaces/MediaType";
import CategoryPackItem from "@/store/interfaces/CategoryPackItem";
import Vue from "vue";
import Pack from "@/store/models/Pack";
import PackDescriptionItem from "@/store/interfaces/PackDescriptionItem";
import Category from "@/store/models/Category";
import rules from "@/modules/rules";

export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    totalRatio() {
      let total = 0;
      for (const category of this.filteredCategories) {
        total += category.ratio;
      }
      return total;
    },
    filteredCategories() {
      return this.categories.filter((value) => {
        return !value.markedForDeletion;
      });
    },
    maleFile: {
      get(): File | null {
        return this.male as File;
      },
      set(value: File) {
        try {
          if (value) {
            if (this.validateThumbnail(value)) {
              this.male = value;
              this.maleSrc = URL.createObjectURL(value);
              this.maleChanged = true;
            } else {
              this.male = null;
              this.maleSrc = base64Images.thumbnailPlaceholder;
            }
          } else {
            this.male = value;
            this.maleSrc = base64Images.thumbnailPlaceholder;
            this.maleChanged = true;
          }
        } catch (error) {
          this.$store.dispatch("showAlert", {
            message: error.message,
            color: "warning",
            timeout: 4000,
          });
        }
      },
    },
    femaleFile: {
      get(): File | null {
        return this.female as File;
      },
      set(value: File) {
        try {
          if (value) {
            if (this.validateThumbnail(value)) {
              this.female = value;
              this.femaleSrc = URL.createObjectURL(value);
              this.femaleChanged = true;
            } else {
              this.female = null;
              this.femaleSrc = base64Images.thumbnailPlaceholder;
            }
          } else {
            this.female = value;
            this.femaleSrc = base64Images.thumbnailPlaceholder;
            this.femaleChanged = true;
          }
        } catch (error) {
          this.$store.dispatch("showAlert", {
            message: error.message,
            color: "warning",
            timeout: 4000,
          });
        }
      },
    },
    showCategorySelection: {
      get(): boolean {
        return this.showCategorySelectionDialog;
      },
      set(value: boolean) {
        if (!value) {
          this.showCategorySelectionDialog = false;
          this.selectedCategoryText = "";
          this.selectedCategoryId = 0;
        }
      },
    },
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.pack = null;
          this.packObjectLoaded = false;
          this.packObjectChanged = false;
          this.maleSrc = base64Images.thumbnailPlaceholder;
          this.femaleSrc = base64Images.thumbnailPlaceholder;

          this.$emit("close");
          setTimeout(() => {
            this.maleFile = null;
            this.femaleFile = null;
            this.maleChanged = false;
            this.femaleChanged = false;
            this.updateLoading = false;
            this.categories = [];
            this.descriptions = [];
          }, 300);
        }
      },
    },
  },
  data: () => {
    return {
      pack: null as null | Pack,
      packObjectLoaded: false,
      packObjectChanged: false,
      maleSrc: base64Images.thumbnailPlaceholder,
      maleChanged: false,
      male: null as File | null,
      femaleSrc: base64Images.thumbnailPlaceholder,
      femaleChanged: false,
      female: null as File | null,
      categories: [] as CategoryPackItem[],
      selectedCategoryText: "",
      selectedCategoryId: 0,
      showCategorySelectionDialog: false,
      descriptions: [] as PackDescriptionItem[],
      deleteDialog: false,
      deleteLoading: false,
      updateLoading: false,
      valid: false,
      validDescriptions: false,
      rules: rules(),
    };
  },
  watch: {
    async id() {
      // If ID, then we fetch the details
      if (this.id) {
        // Fetch video object
        this.getPack();
      }
    },
    pack: {
      deep: true,
      handler(newValue, oldValue) {
        if (oldValue && this.packObjectLoaded) {
          this.packObjectChanged = true;
        }
      },
    },
    descriptions: {
      deep: true,
      handler() {
        if (this.packObjectLoaded) {
          this.packObjectChanged = true;
        }
      },
    },
    categories: {
      deep: true,
      handler() {
        if (this.packObjectLoaded) {
          this.packObjectChanged = true;
        }
      },
    },
  },
  methods: {
    addCategory() {
      // First we get the name of the category
      const categoryName = (
        this.$store.state.categories.move as Category[]
      ).find((a) => {
        return a.id == this.selectedCategoryId;
      })?.name;
      // Then we need to calculate the remaining ratio to get to 100
      let ratio = 100 - this.totalRatio;
      if (ratio < 0) {
        ratio = 0;
      }
      this.categories.push({
        markedForDeletion: false,
        categoryId: this.selectedCategoryId,
        categoryName: categoryName ? categoryName : "",
        ratio,
      });
      this.showCategorySelection = false;
    },
    async updatePack() {
      try {
        this.updateLoading = true;
        if (this.pack) {
          if (this.maleChanged) {
            // If the user has deleted the thumbnail, we send the appropriate request (an empty thumbnail route)
            if (!this.maleFile) {
              const thumbnailDeleted = await Request.shared.put(
                `pack/${this.pack.id}/pic/male`
              );
            } else {
              // The user has set a new thumbnail, we need to create the media and assign it to the video
              const mediaBody = {
                type: MediaType.packItem,
                alt: this.maleFile.name,
              };
              // We create the formdata
              const formData = new FormData();
              formData.append("body", JSON.stringify(mediaBody));
              formData.append("media", this.maleFile);
              const media = await Request.shared.post("media", formData);
              // Once we got the media, we can assign the thumbnail to the video
              const assignedMedia = await Request.shared.put(
                `pack/${this.pack.id}/pic/male/${media.data.id}`
              );
            }
          }
          if (this.femaleChanged) {
            // If the user has deleted the thumbnail, we send the appropriate request (an empty thumbnail route)
            if (!this.femaleFile) {
              console.log("y'a pas d'image biatch");
              const thumbnailDeleted = await Request.shared.put(
                `pack/${this.pack.id}/pic/female`
              );
            } else {
              // The user has set a new thumbnail, we need to create the media and assign it to the video
              const mediaBody = {
                type: MediaType.packItem,
                alt: this.femaleFile.name,
              };
              // We create the formdata
              const formData = new FormData();
              formData.append("body", JSON.stringify(mediaBody));
              formData.append("media", this.femaleFile);
              const media = await Request.shared.post("media", formData);
              // Once we got the media, we can assign the thumbnail to the video
              const assignedMedia = await Request.shared.put(
                `pack/${this.pack.id}/pic/female/${media.data.id}`
              );
            }
          }

          // CATEGORY PACKS
          for (const categoryPack of this.categories) {
            // If it has an ID, it's an existing pack
            if (categoryPack.categoryPackId) {
              // If it's marked for deletion, we delete it
              if (categoryPack.markedForDeletion) {
                await Request.shared.delete(
                  `pack/${this.id}/categorypack/${categoryPack.categoryPackId}`
                );
              } else {
                // Else we update the ratio in case it has changed
                await Request.shared.put(
                  `pack/${this.id}/categorypack/${categoryPack.categoryPackId}`,
                  { ratio: categoryPack.ratio }
                );
              }
            } else {
              // Else it means that the categoryPack doesn't exist yet, we create it only if it's not marked for deletion
              if (!categoryPack.markedForDeletion) {
                await Request.shared.post(
                  `pack/${this.id}/categorypack/${categoryPack.categoryId}`,
                  { ratio: categoryPack.ratio }
                );
              }
            }
          }

          // PACK Descriptions
          for (const description of this.descriptions) {
            if (description.id) {
              // If it has an ID, it's not new
              if (description.markedForDeletion) {
                // If it's flagged for deletion, we delete it (can't happen)
                await Request.shared.delete(
                  `pack/${this.id}/description/${description.id}`
                );
              } else {
                // We update the value
                await Request.shared.put(
                  `pack/${this.id}/description/${description.id}`,
                  { value: description.value }
                );
              }
            } else {
              // It means that it's a new one, we create it
              await Request.shared.post(`pack/${this.id}/description`, {
                value: description.value,
              });
            }
          }

          const packUpdated = await Request.shared.put(
            `pack/${this.pack.id}`,
            this.pack
          );
        }
        this.updateLoading = false;
        this.$store.dispatch("showAlert", {
          message: "Le pack a été miss à jour",
          color: "success",
          timeout: 4000,
        });
        // Rebuild original object
        await this.getPack();
        this.$emit("updated");
        this.maleChanged = false;
        this.femaleChanged = false;
        const data = this.$data;
        setTimeout(() => {
          data.packObjectChanged = false;
        }, 100);
      } catch (error) {
        console.error(error);
      }
    },
    async getPack() {
      try {
        this.packObjectLoaded = false;
        const pack = await Request.shared.get(`pack/${this.id}`);
        this.pack = pack.data;

        if (this.pack!.MalePic) {
          try {
            const imageRequest = await Request.shared.get(
              `content/${this.$store.state.admin.uuid}/media/${this.pack?.MalePic.filename}`,
              {
                responseType: "blob",
              }
            );
            this.maleSrc = URL.createObjectURL(imageRequest.data);
          } catch (error) {
            console.log(error);
            // Show alert but don't close
          }
        }
        if (this.pack!.FemalePic) {
          try {
            const imageRequest = await Request.shared.get(
              `content/${this.$store.state.admin.uuid}/media/${this.pack?.FemalePic.filename}`,
              {
                responseType: "blob",
              }
            );
            this.femaleSrc = URL.createObjectURL(imageRequest.data);
          } catch (error) {
            console.log(error);
            // Show alert but don't close
          }
        }
        // Create the category array
        this.categories = [];
        for (const categoryPack of this.pack!.CategoryPacks!) {
          this.categories.push({
            categoryPackId: categoryPack.id,
            markedForDeletion: false,
            categoryId: categoryPack.Category.id,
            categoryName: categoryPack.Category.name,
            ratio: categoryPack.ratio,
          });
        }
        // Create the description array :

        this.descriptions = [];
        for (const description of this.pack!.PackDescriptions!) {
          this.descriptions.push({
            id: description.id,
            markedForDeletion: false,
            value: description.value,
          });
        }
        // If the length was < 4, (e.g new pack), we create placeholders
        if (this.pack!.PackDescriptions!.length < 4) {
          for (let i = 0; i < 4 - length; i++) {
            this.descriptions.push({
              markedForDeletion: false,
              value: "",
            });
          }
        }
        const data = this.$data;
        setTimeout(() => {
          data.packObjectLoaded = true;
        }, 100);
      } catch (error) {
        console.error(error);
      }
    },
    async deletePack(id: number) {
      this.deleteLoading = true;
      await Request.shared.delete(`pack/${id}`);
      await this.$store.dispatch("getPacks");
      this.deleteLoading = false;
      this.showDialog = false;
    },
    selectImage(picker: string) {
      ((this.$refs as any)[picker] as any).$refs.input.click();
    },
    handleDrop(e: DragEvent, field: string) {
      if (e.dataTransfer!.files.length) {
        // Switch field
        switch (field) {
          case "male":
            this.maleFile = e.dataTransfer!.files[0];
            break;
          case "female":
            this.femaleFile = e.dataTransfer!.files[0];
        }
        //this.thumbnailFile = e.dataTransfer!.files[0];
      }
    },
    validateThumbnail(file: File) {
      if (file.type == "image/png" || file.type == "image/jpeg") {
        // We check the file size
        if (file.size < 300000) {
          return true;
        } else {
          throw {
            message:
              "Le fichier est trop volumineux, sa taille doit être inférieure à 300ko",
          };
        }
      } else {
        throw {
          message: "Veuillez utiliser un fichier .jpg ou .png",
        };
      }
    },
  },
});
